import { createBrowserHistory } from 'history'

export const routes = {
  home: '/',
} as const

// ---

const HISTORY_BASENAME = '/'

export const history = createBrowserHistory({
  basename: HISTORY_BASENAME,
})

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardNum_root__13gqT {\n  min-width: 240px;\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n  display: grid;\n  grid-template-rows: 1fr auto;\n}\n\n.CardNum_content__2F-PD {\n  font-size: 30px;\n  font-weight: bold;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.CardNum_content__2F-PD svg {\n  color: var(--th-clr-primary);\n}", "",{"version":3,"sources":["webpack://src/apps/operator/features/Dashboard/components/CardNum.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,2BAAA;EAAA,sBAAA;EACA,aAAA;EACA,4BAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAEE;EACE,4BAAA;AAAJ","sourcesContent":[".root {\n  min-width: 240px;\n  max-width: fit-content;\n  display: grid;\n  grid-template-rows: 1fr auto;\n}\n\n.content {\n  font-size: 30px;\n  font-weight: bold;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  svg {\n    color: var(--th-clr-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CardNum_root__13gqT",
	"content": "CardNum_content__2F-PD"
};
export default ___CSS_LOADER_EXPORT___;

import { Loader, Page } from 'src/components'

import {
  Dashboard,
  DashboardApi,
  IPlatformStatistics,
} from '../features/Dashboard'

const DEFAULT_STATS: IPlatformStatistics = {
  investorsCount: 0,
  avatarsCount: 0,
  adviserCount: 0,
  signedAgreementsCount: 0,
  avatarPerAdviser: {},
  auaPerAdviser: {},
  walletStatisticsResult: [],
}

export function DashboardPage() {
  const { data = DEFAULT_STATS, isLoading } =
    DashboardApi.endpoints.getStatistics.useQuery(undefined, {
      pollingInterval: 60 * 1000,
    })

  return (
    <Page>
      <Choose>
        <When condition={isLoading}>
          <Loader />
        </When>

        <Otherwise>
          <Dashboard data={data} />
        </Otherwise>
      </Choose>
    </Page>
  )
}

import React from 'react'
import { Switch } from 'react-router-dom'

import { AuthRoute } from 'src/features/User'

import { DashboardPage } from './pages/Dashboard'
import { routes } from './routes'

export default function AppRouter() {
  return (
    <Switch>
      <AuthRoute path={routes.home} component={DashboardPage} exact />
    </Switch>
  )
}

import { AgreementStatusIcon, Card, Grid, UserRoleIcon } from 'src/components'
import { AgreementStatus, UserRole } from 'src/types'
import { formatMoney, getUserRoleLabel } from 'src/utils'

import { IPlatformStatistics } from '../types'

import { CardDict } from './CardDict'
import { CardNum } from './CardNum'

export function Dashboard(props: { data: IPlatformStatistics }) {
  const { data } = props
  return (
    <Grid>
      <Grid columns autoColumns="max-content">
        <Card title="Users on platform">
          <Grid columns={3} autoColumns="max-content">
            <CardNum
              title="Investors count"
              icon={<UserRoleIcon role={UserRole.Investor} />}
            >
              {data.investorsCount}
            </CardNum>
            <CardNum
              title="Avatars count"
              icon={<UserRoleIcon role={UserRole.Avatar} />}
            >
              {data.avatarsCount}
            </CardNum>
            <CardNum
              title={`${getUserRoleLabel(UserRole.Advisor)} count`}
              icon={<UserRoleIcon role={UserRole.Advisor} />}
            >
              {data.adviserCount}
            </CardNum>
          </Grid>
        </Card>

        <Card title="&nbsp;">
          <CardNum
            title="Signed agreements"
            icon={<AgreementStatusIcon status={AgreementStatus.Signed} />}
          >
            {data.signedAgreementsCount}
          </CardNum>
        </Card>
      </Grid>

      <Card title="Wallets statistics">
        <Choose>
          <When condition={data.walletStatisticsResult.length > 0}>
            <Grid columns style={{ justifyContent: 'flex-start' }}>
              {data.walletStatisticsResult.map(x => (
                <CardWallet {...x} key={x.walletType} />
              ))}
            </Grid>
          </When>

          <Otherwise>No data available</Otherwise>
        </Choose>
      </Card>

      <Card title={`${getUserRoleLabel(UserRole.Advisor)} statistics`}>
        <Grid columns={2}>
          <CardDict title={`Avatars per ${getUserRoleLabel(UserRole.Advisor)}`}>
            {data.avatarPerAdviser}
          </CardDict>

          <CardDict
            title={`Assets per ${getUserRoleLabel(UserRole.Advisor)}`}
            renderValue={formatMoney}
          >
            {data.auaPerAdviser}
          </CardDict>
        </Grid>
      </Card>
    </Grid>
  )
}

function CardWallet(props: IPlatformStatistics['walletStatisticsResult'][0]) {
  const { walletType, count } = props
  return <CardNum title={walletType}>{count}</CardNum>
}

import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import clsx from 'clsx'
import { sortBy, sumBy } from 'lodash'
import { Overwrite } from 'utility-types'

import { Card, Grid, ICardProps } from 'src/components'

import styles from './CardDict.module.scss'

const DefaultNumberFormatter = new Intl.NumberFormat()

interface ICardDictProps
  extends Overwrite<
    ICardProps,
    {
      children: Dict<number> | null
    }
  > {
  renderValue?: (x: number) => ReactNode
}

export function CardDict(props: ICardDictProps) {
  const {
    children: data,
    renderValue = DefaultNumberFormatter.format,
    ...rest
  } = props

  const {
    data: entries,
    sorting,
    toggleSort,
  } = useSort(
    useMemo(() => (data === null ? [] : Object.entries(data)), [data]),
    0
  )

  const total = useMemo(() => sumBy(entries, x => x[1]), [entries])

  const keys = entries.map(x => x[0])
  const values = entries.map(x => x[1])

  return (
    <Card {...rest} subtitle={renderValue(total)}>
      <Grid columns="300px auto">
        <SortableColumn name={0} state={sorting} toggleSort={toggleSort}>
          <Grid>
            {keys.map(x => (
              <span key={x} className={styles.col_key}>
                {x}
              </span>
            ))}
          </Grid>
        </SortableColumn>

        <SortableColumn name={1} state={sorting} toggleSort={toggleSort}>
          <Grid>
            {values.map((x, i) => (
              <span key={i} className={styles.col_value}>
                {renderValue(x)}
              </span>
            ))}
          </Grid>
        </SortableColumn>
      </Grid>
    </Card>
  )
}

// ---

function SortableColumn<Name>(props: {
  children: ReactElement
  name: Name
  state: ISortingState<Name>
  toggleSort: (name: Name) => void
}) {
  const { children, state, toggleSort, name } = props
  const child = React.Children.only(children)
  const isSorted = state.column === name
  const isAsc = state.asc
  return React.cloneElement(child, {
    onClick: () => toggleSort(name),
    className: clsx(child.props.className, styles.sortable_column, {
      [styles.sort_asc]: isSorted && isAsc,
      [styles.sort_desc]: isSorted && !isAsc,
      [styles.sort_none]: !isSorted,
    }),
  })
}

interface ISortingState<Column = unknown> {
  asc: boolean
  column: Column | undefined
}

function useSort<T>(data: T[], defaultColumn?: keyof T) {
  type Column = keyof T
  const [state, setState] = useState<ISortingState<Column>>({
    asc: true,
    column: defaultColumn,
  })

  const toggleSort = useCallback((col: Column) => {
    setState(state => ({
      column: col,
      asc: col === state.column ? !state.asc : state.asc,
    }))
  }, [])

  const sorted = useMemo(() => {
    const { asc, column } = state

    if (column === undefined) {
      return data
    }

    const sorted = sortBy(data, x => x[column])
    if (!asc) {
      sorted.reverse()
    }
    return sorted
  }, [data, state])

  return { data: sorted, sorting: state, toggleSort }
}

import React, { ReactElement } from 'react'
import { IconBaseProps, IconType } from 'react-icons'

import clsx from 'clsx'

import { Card, Flex, ICardProps } from 'src/components'

import styles from './CardNum.module.scss'

export function CardNum(
  props: ICardProps & {
    icon?: ReactElement<IconBaseProps, IconType>
  }
) {
  const { className, icon, children, ...rest } = props

  return (
    <Card spacing="sm" {...rest} className={clsx(styles.root, className)}>
      <div className={styles.content}>
        <Choose>
          <When condition={icon === undefined}>{children}</When>

          <Otherwise>
            <Flex align="center">
              {React.cloneElement(icon as ReactElement, {
                size: 40,
              })}
              {children}
            </Flex>
          </Otherwise>
        </Choose>
      </div>
    </Card>
  )
}

import React from 'react'
import { setDefaultErrorBoundaryFallback } from 'react-app-error-boundary'

import AppBase, { DefaultErrorBoundaryFallback } from 'src/AppBase'
import { AppSettings } from 'src/AppSettingsContext'
import { ThemeProvider } from 'src/ThemeProvider'

import Router from './Router'
import { OperatorLayout } from './components'
import { login } from './features/User'
import { history, routes } from './routes'
import { store } from './store'

setDefaultErrorBoundaryFallback(DefaultErrorBoundaryFallback)

const App: React.FC = () => {
  return (
    <ThemeProvider theme="polity">
      <AppSettings routes={routes}>
        <AppBase history={history} store={store} login={login}>
          <OperatorLayout>
            <Router />
          </OperatorLayout>
        </AppBase>
      </AppSettings>
    </ThemeProvider>
  )
}

export default App

import { OperatorApi } from '../../api'

import { IPlatformStatistics } from './types'

export const DashboardApi = OperatorApi.injectEndpoints({
  endpoints: builder => ({
    getStatistics: builder.query<IPlatformStatistics, void>({
      query: () => 'statistics',
    }),
  }),
})

import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from 'src/api'

export const OperatorApi = createRTQApi({
  reducerPath: 'operator_api',

  baseQuery: axiosBaseQuery({
    baseURL: 'operator',
  }),

  tagTypes: [],

  endpoints: _ => ({}),
})

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardDict_col_key__3lQAn {\n  /* empty */\n}\n\n.CardDict_col_value__3bLXR {\n  font-weight: bold;\n}\n\n.CardDict_sortable_column__2LfXV {\n  padding: 8px;\n  border-radius: 16px;\n}\n.CardDict_sortable_column__2LfXV:hover {\n  background-color: var(--th-clr-success-25);\n}\n\n.CardDict_sort_asc__D6FZH {\n  cursor: n-resize;\n}\n\n.CardDict_sort_desc__3wv-4 {\n  cursor: s-resize;\n}\n\n.CardDict_sort_none__uAvrH {\n  cursor: ns-resize;\n}", "",{"version":3,"sources":["webpack://src/apps/operator/features/Dashboard/components/CardDict.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;AACE;EACE,0CAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".col_key {\n  /* empty */\n}\n\n.col_value {\n  font-weight: bold;\n}\n\n.sortable_column {\n  padding: 8px;\n  border-radius: 16px;\n\n  &:hover {\n    background-color: var(--th-clr-success-25);\n  }\n}\n\n.sort_asc {\n  cursor: n-resize;\n}\n\n.sort_desc {\n  cursor: s-resize;\n}\n\n.sort_none {\n  cursor: ns-resize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col_key": "CardDict_col_key__3lQAn",
	"col_value": "CardDict_col_value__3bLXR",
	"sortable_column": "CardDict_sortable_column__2LfXV",
	"sort_asc": "CardDict_sort_asc__D6FZH",
	"sort_desc": "CardDict_sort_desc__3wv-4",
	"sort_none": "CardDict_sort_none__uAvrH"
};
export default ___CSS_LOADER_EXPORT___;

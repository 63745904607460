// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_root__3atVV {\n  overflow: hidden;\n  height: 100vh;\n  min-width: 320px;\n  margin-inline: auto;\n  display: grid;\n  grid-template-rows: 72px 1fr;\n  background-color: var(--th-clr-bg-body);\n}\n\n.Layout_header__1H7Zx {\n  padding: 16px 80px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  grid-gap: 1rem;\n  gap: 1rem;\n  overflow: hidden;\n  border-bottom: 1px solid var(--th-clr-gray-300);\n  background-color: var(--th-clr-bg-primary);\n}\n\n.Layout_logo__-tr-X {\n  color: var(--th-clr-txt-primary);\n  vertical-align: middle;\n  --logo-clr-icon: var(--th-clr-primary);\n}\n\n.Layout_content__2YZh5 {\n  padding: 48px 80px;\n  overflow: auto;\n}", "",{"version":3,"sources":["webpack://src/apps/operator/components/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,gBAAA;EAEA,mBAAA;EAEA,aAAA;EACA,4BAAA;EAEA,uCAAA;AAFF;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EAAA,SAAA;EACA,gBAAA;EACA,+CAAA;EACA,0CAAA;AAFF;;AAKA;EACE,gCAAA;EACA,sBAAA;EACA,sCAAA;AAFF;;AAKA;EACE,kBAAA;EACA,cAAA;AAFF","sourcesContent":[".root {\n  overflow: hidden;\n  height: 100vh;\n  min-width: 320px;\n\n  margin-inline: auto;\n\n  display: grid;\n  grid-template-rows: 72px 1fr;\n\n  background-color: var(--th-clr-bg-body);\n}\n\n.header {\n  padding: 16px 80px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 1rem;\n  overflow: hidden;\n  border-bottom: 1px solid var(--th-clr-gray-300);\n  background-color: var(--th-clr-bg-primary);\n}\n\n.logo {\n  color: var(--th-clr-txt-primary);\n  vertical-align: middle;\n  --logo-clr-icon: var(--th-clr-primary);\n}\n\n.content {\n  padding: 48px 80px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Layout_root__3atVV",
	"header": "Layout_header__1H7Zx",
	"logo": "Layout_logo__-tr-X",
	"content": "Layout_content__2YZh5"
};
export default ___CSS_LOADER_EXPORT___;

import React, { ReactNode } from 'react'

import { routes } from 'src/apps/operator'
import { AuthMenu, Link } from 'src/components'
import { ReactComponent as Logo } from 'src/logo-polity.svg'

import { logout } from '../features/User'
import { useDispatch } from '../store'

import styles from './Layout.module.scss'

export interface IAuthLayoutProps {
  children: ReactNode
  hrefHome?: string
}

export function OperatorLayout(props: IAuthLayoutProps) {
  const { children, hrefHome = routes.home } = props

  const dispatch = useDispatch()
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Link to={hrefHome}>
          <Logo className={styles.logo} />
        </Link>

        <AuthMenu onLogout={() => dispatch(logout())} />
      </header>

      <main className={styles.content}>{children}</main>
    </div>
  )
}
